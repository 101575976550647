import { desktopMenu } from './desktop-menu';
import { gforms } from './gforms';
import { mobileMenu } from './mobile-menu';
// import { carousel } from './carousel';
import { testimonials } from './testimonials';
import { tabs } from './tabs';
import { postBlock } from './post-block';

const rdy = (fn: () => void) => {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
};

// on dom content loaded
rdy(() => {
  desktopMenu();
  mobileMenu();
  gforms();
  // carousel();
  testimonials();
  tabs();
  postBlock();
});
