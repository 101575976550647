export const tabs = () => {
  const tabs = document.querySelector('.team-tabs') as HTMLElement;

  if (!tabs) return;

  const tabControls = tabs.querySelectorAll('.tab-controls button') as NodeListOf<HTMLElement>;
  const tabContents = tabs.querySelectorAll('.tab-contents > div') as NodeListOf<HTMLElement>;

  tabControls.forEach((tabControl, index) => {
    tabControl.addEventListener('click', (e) => {
      // remove active class from tabs
      tabContents.forEach((tabContent) => {
        tabContent.classList.remove('is-active');
      });
      tabControls.forEach((tabControl) => {
        tabControl.classList.remove('is-active');
      });

      // add active class to tab
      tabControl.classList.add('is-active');
      tabContents[index].classList.add('is-active');
    });
  });
};
