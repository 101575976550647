import Splide from '@splidejs/splide';

export const testimonials = () => {
  const carousels = document.querySelectorAll('.wp-block-testimonials .splide') as NodeListOf<HTMLElement>;

  if (!carousels.length) return;

  carousels.forEach((carousel) => {
    console.log(carousel);

    const splide = new Splide(carousel, {
      autoplay: true,
      autoplaySpeed: 5000,
      type: 'loop',
      focus: 'center',
      gap: 48,
      arrows: true,
      pagination: false,
    });

    splide.mount();

    console.log(splide);
  });
};
