export const postBlock = () => {
  const postBlocks = document.querySelectorAll('.wp-block-post.team');

  if (!postBlocks.length) return;

  postBlocks.forEach((block) => {
    const group = block.querySelector('.wp-block-group');
    const link = group?.querySelector('a');

    if (!group || !link) return;

    group.addEventListener('click', (e: any) => {
      if (e.target.tagName === 'A') return;

      link.click();
    });
  });
};
